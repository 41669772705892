import { Dispatch, ReactElement, SetStateAction } from 'react'

import clsx from 'clsx'
import { Button, ButtonVariant } from '../Button'
import { Checkbox, CheckboxProps } from '../Checkbox'
import { ScrollArea } from '../ScrollArea'
import { H3 } from '../Typo/Header'

export type CheckboxOption<T = unknown> = T &
  Pick<CheckboxProps, 'htmlId' | 'label' | 'children'> & {
    isChecked: boolean
  }

interface CheckboxGroupProps<T> {
  title?: string | ReactElement
  values: CheckboxOption<T>[]
  setValues: Dispatch<SetStateAction<CheckboxOption<T>[]>>
  scrollAreaClassName?: string
  className?: string
}

export function CheckboxGroup<T>({
  title,
  values,
  setValues,
  scrollAreaClassName = 'max-h-48',
  className,
}: CheckboxGroupProps<T>) {
  const areAllChecked = values.every(value => value.isChecked)

  function flipCheck() {
    setValues(previousValues =>
      previousValues.map(previousValue => ({
        ...previousValue,
        isChecked: !areAllChecked,
      }))
    )
  }

  return (
    <div className={clsx(className, 'flex flex-col gap-2')}>
      <div className="flex flex-wrap gap-2 justify-between">
        {title && (typeof title === 'string' ? <H3>{title}</H3> : title)}

        <Button
          variant={ButtonVariant.Secondary}
          usePadding={false}
          className="px-1"
          onClick={flipCheck}
        >
          {areAllChecked ? 'Deselect all' : 'Select all'}
        </Button>
      </div>

      <ScrollArea className={scrollAreaClassName} type="always">
        {values.map(value => (
          <Checkbox
            key={value.htmlId}
            htmlId={value.htmlId}
            label={value.label}
            checked={value.isChecked}
            onCheckedChange={() => {
              setValues(previousValues =>
                previousValues.map(previousValue =>
                  previousValue.htmlId === value.htmlId
                    ? { ...previousValue, isChecked: !previousValue.isChecked }
                    : previousValue
                )
              )
            }}
          >
            {value.children}
          </Checkbox>
        ))}
      </ScrollArea>
    </div>
  )
}
