import { useTranslation } from 'react-i18next'

import { homepageIllustration } from '~/assets/images'
import { Namespace } from '~/i18n'
import useLocalizePathname from '~/i18n/use-localize-pathname'
import {
  Button,
  ButtonVariant,
  Hero,
  HeroImage,
  Icon,
  IconName,
} from '~/tenset-components'

export function HeroHP() {
  const { t } = useTranslation([Namespace.HOMEPAGE, Namespace.COMMON])
  const localizePathname = useLocalizePathname()

  return (
    <div className="grid place-items-center">
      <Hero
        title={t('hero.title')}
        description={t('hero.description')}
        rightContent={
          <HeroImage src={homepageIllustration} alt={t('hero.title')} />
        }
        leftContent={
          <>
            <div className="mb-8 -mt-4 text-base font-normal sm:text-lg">
              {t('hero.description-2')}{' '}
              <div>
                <Button
                  to="https://www.bnbchain.org/en/developers/developer-programs/kickstart"
                  variant={ButtonVariant.Link}
                >
                  <Icon name={IconName.BnbChain} />
                  <span className="mt-[2px] text-lg font-medium text-white">
                    KICKSTART
                  </span>
                </Button>
              </div>
            </div>
            <div className="flex flex-col gap-4 md:flex-row">
              <Button to={localizePathname('/gem-launch-platform')}>
                {t('common:discover-gems')}
              </Button>

              <Button
                to={localizePathname('/gem-launch-platform')}
                variant={ButtonVariant.Ghost}
              >
                {t('common:subscribe-tglp')}
              </Button>
            </div>
          </>
        }
      />
    </div>
  )
}
