import { BigNumber, ContractInterface } from 'ethers'

import { glpSubscribeAbi } from '../../assets'
import { GlpAffiliateCode, Utils } from '../../types'
import { Contract } from '../index'

export class GlpSubscribeContract extends Contract {
  constructor(
    address: string,
    chain: Utils.Network,
    abi: ContractInterface = glpSubscribeAbi,
    rpcUrl?: string
  ) {
    super(address, chain, abi, rpcUrl)
  }

  async tglpMembership(): Promise<string> {
    return await this.adapter!.tglpMembership()
  }

  async subscribe(
    quantity: BigNumber,
    tierId: number,
    tokenId: number,
    subscriber: string,
    affiliateCode: GlpAffiliateCode
  ): Promise<void> {
    this.wallet!.asBusy()

    try {
      const tx = await this.adapter!.subscribe(
        quantity,
        tierId,
        tokenId,
        subscriber,
        affiliateCode
      )

      await tx.wait()
    } catch (error) {
      this.wallet!.asIdle()

      throw error
    }

    this.wallet!.asIdle()
  }
}
