import clsx from 'clsx'
import { InputHTMLAttributes, ReactElement } from 'react'

export type InputType = 'text' | 'tel' | 'password' | 'number'

export type InputSize = 'small' | 'medium'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  info?: string | ReactElement
  errorMessage?: string
  message?: string
  type?: InputType
  preInput?: ReactElement
  postInput?: ReactElement
  inputSize?: InputSize
  className?: string
}

function dynamicStyles(inputSize: InputSize) {
  const isSmall = inputSize === `small`

  const textSize = isSmall ? 'text-sm' : 'text-base'
  const inputHeigh = isSmall ? 'h-10' : 'h-[52px]'
  const paddingLeft = isSmall ? 'pl-2.5' : 'pl-4'
  const focusPaddingLeft = isSmall ? 'focus:pl-[9px]' : 'focus:pl-[15px]'
  const activePaddingLeft = isSmall ? 'active:pl-[9px]' : 'active:pl-[15px]'
  const disabledPaddingLeft = isSmall
    ? 'disabled:pl-[9px]'
    : 'disabled:pl-[15px]'
  const focusPaddingLeftError = isSmall ? 'focus:pl-2.5' : 'focus:pl-4'
  const activePaddingLeftError = isSmall ? 'active:pl-2.5' : 'active:pl-4'

  return {
    textSize,
    inputHeigh,
    paddingLeft,
    focusPaddingLeft,
    activePaddingLeft,
    disabledPaddingLeft,
    focusPaddingLeftError,
    activePaddingLeftError,
  }
}

export function Input({
  label,
  info,
  placeholder,
  errorMessage,
  message,
  name,
  required = true,
  disabled = false,
  type = 'text',
  preInput,
  postInput,
  inputSize = 'small',
  className,
  ...props
}: InputProps) {
  const isError = errorMessage && errorMessage.length > 0

  const {
    textSize,
    inputHeigh,
    paddingLeft,
    focusPaddingLeft,
    activePaddingLeft,
    disabledPaddingLeft,
    focusPaddingLeftError,
    activePaddingLeftError,
  } = dynamicStyles(inputSize)

  return (
    <div className="w-full">
      <div className="flex justify-between">
        {label && (
          <label className="block pb-2 text-sm font-semibold">
            {label}
            {label && required ? '*' : ''}
          </label>
        )}
        {info && (
          <span className="block pb-2 text-sm font-semibold">{info}</span>
        )}
      </div>
      <div className={clsx((preInput || postInput) && 'flex')}>
        {preInput && <>{preInput}</>}
        <input
          name={name}
          className={clsx(
            className,
            'appearance-none',
            'focus:outline-none',
            'focus:border-blue-500',
            'focus:border-2',
            'border',
            'border-neutral-400',
            'bg-neutral-700',
            'rounded',
            'p-px',
            'focus:p-0',
            focusPaddingLeft,
            activePaddingLeft,
            inputHeigh,
            'w-full',
            textSize,
            paddingLeft,
            'disabled:cursor-not-allowed',
            'disabled:p-0',
            disabledPaddingLeft,
            'caret-blue-500',
            'placeholder-neutral-300',
            disabled && 'text-neutral-300',
            isError &&
              clsx(
                'border-red-500',
                'focus:border-red-500',
                'focus:p-px',
                focusPaddingLeftError,
                activePaddingLeftError,
                'focus:border',
                'caret-red-500'
              ),
            preInput && 'rounded-l-none border-l-0',
            postInput && 'rounded-r-none border-r-0'
          )}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          {...props}
        />
        {postInput && <>{postInput}</>}
      </div>
      {isError && (
        <div className="pt-2 text-sm text-red-100">{errorMessage}</div>
      )}
      {message && !isError && (
        <div className="pt-2 text-sm text-neutral-100">{message}</div>
      )}
    </div>
  )
}
