export enum Namespace {
  BUSINESS = 'business',
  BURNS = 'burns',
  COMMON = 'common',
  CONTACT_PAGE = 'contact-page',
  FORM_VALIDATORS = 'form-validators',
  GEM = 'gem',
  GEM_LAUNCH_PLATFORM = 'gem-launch-platform',
  GEM_LAUNCH_PLATFORM_SUBSCRIPTION = 'gem-launch-platform-subscription',
  GOVERNANCE = 'governance',
  HOMEPAGE = 'homepage',
  INCUBATOR = 'incubator',
  NEWS = 'news',
  VERIFICATION = 'verification',
  VERIFY_OWNERSHIP = 'verify-ownership',
  ZEALY = 'zealy',
}

export const DEFAULT_NAMESPACE = Namespace.COMMON

export const NAMESPACES = Object.values(Namespace)
