import { useContext, useEffect, useState } from 'react'

import {
  GlpExtendContract,
  GlpLifetimeMembershipContract,
  GlpMembershipContract,
  GlpOldWithdrawContract,
  GlpSubscribeContract,
  GlpUpgradeContract,
  GlpWithdrawContract,
  Wallet,
  config,
  walletContext,
} from '../..'
import { GlpContracts, Utils } from '../../types'

async function getContracts(
  wallet: Wallet,
  network: Utils.Network
): Promise<GlpContracts> {
  if (!wallet.isOnChain(network)) return {}

  const { subscribe, withdraw, extend, upgrade, oldWithdraw } =
    config.gemLaunchPlatform

  const subscribeContract = new GlpSubscribeContract(
    subscribe[network]!,
    network
  )
  const withdrawContract = new GlpWithdrawContract(withdraw[network]!, network)
  const extendContract = new GlpExtendContract(extend[network]!, network)
  const upgradeContract = new GlpUpgradeContract(upgrade[network]!, network)

  const membershipAddress = await wallet
    .interact(subscribeContract)
    .tglpMembership()

  const membershipContract = new GlpMembershipContract(
    membershipAddress,
    network
  )

  const lifetimeMembershipAddress = await wallet
    .interact(upgradeContract)
    .tglpLifetimeMembership()

  const lifetimeMembershipContract = new GlpLifetimeMembershipContract(
    lifetimeMembershipAddress,
    network
  )

  const oldWithdrawContract = new GlpOldWithdrawContract(
    oldWithdraw[network]!,
    network
  )

  return {
    glpSubscribeContract: subscribeContract,
    glpMembershipContract: membershipContract,
    glpWithdrawContract: withdrawContract,
    glpExtendContract: extendContract,
    glpUpgradeContract: upgradeContract,
    glpLifetimeMembershipContract: lifetimeMembershipContract,
    glpOldWithdrawContract: oldWithdrawContract,
  }
}

export const useGlpContracts = (network: Utils.Network): GlpContracts => {
  const wallet = useContext(walletContext)

  const [contracts, setContracts] = useState<GlpContracts>({})

  useEffect(() => {
    if (!wallet?.isConnected || !network) return

    getContracts(wallet, network).then(setContracts)
  }, [wallet, network, wallet?.isConnected])

  return contracts
}
