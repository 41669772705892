import { useContext, useEffect, useState } from 'react'

import { config } from '../../'
import { Erc721MultisenderContract } from '../../contracts'
import { Utils } from '../../types'
import { walletContext } from '../../wallet'

export const useErc721MultisenderContract = (network: Utils.Network) => {
  const wallet = useContext(walletContext)

  const [erc721MultisenderContract, setErc721MultisenderContract] = useState<
    Erc721MultisenderContract | undefined
  >()

  const { erc721Multisender } = config.utils

  useEffect(() => {
    if (!wallet || !network) return

    const erc721MultisenderContract = new Erc721MultisenderContract(
      erc721Multisender[network]!,
      network
    )

    setErc721MultisenderContract(erc721MultisenderContract)
  }, [wallet, network])

  return { erc721MultisenderContract }
}
