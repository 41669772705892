import { Utils } from '../types'

type Chains = {
  [key in Utils.Network]: {
    name: string
    scannerUrl: string
    currency: string
    rpcUrls: string[]
  }
}

const {
  ETHEREUM,
  ROPSTEN,
  RINKEBY,
  GOERLI,
  SMART_CHAIN,
  SMART_CHAIN_TESTNET,
  POLYGON,
  ARBITRUM_ONE,
  PHALCON,
  BASE,
  ANVIL_1,
  ANVIL_2,
} = Utils.Network

export const chains: Chains = {
  [ETHEREUM]: {
    name: 'Ethereum',
    scannerUrl: 'https://etherscan.io',
    currency: 'ETH',
    rpcUrls: ['https://cloudflare-eth.com'],
  },
  [ROPSTEN]: {
    name: 'Ropsten',
    scannerUrl: 'https://ropsten.etherscan.io',
    currency: 'ETH',
    rpcUrls: ['https://ropsten.infura.io/v3/'],
  },
  [RINKEBY]: {
    name: 'Rinkeby',
    scannerUrl: 'https://rinkeby.etherscan.io',
    currency: 'ETH',
    rpcUrls: ['https://rinkeby.infura.io/v3/'],
  },
  [GOERLI]: {
    name: 'Goerli',
    scannerUrl: 'https://goerli.etherscan.io',
    currency: 'ETH',
    rpcUrls: ['https://rpc.goerli.mudit.blog'],
  },
  [SMART_CHAIN]: {
    name: 'Smart Chain',
    scannerUrl: 'https://bscscan.com',
    currency: 'BNB',
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
  },
  [SMART_CHAIN_TESTNET]: {
    name: 'Smart Chain Testnet',
    scannerUrl: 'https://testnet.bscscan.com',
    currency: 'tBNB',
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
  },
  [POLYGON]: {
    name: 'Polygon',
    scannerUrl: 'https://polygonscan.com',
    currency: 'MATIC',
    rpcUrls: ['https://rpc-mainnet.maticvigil.com/'],
  },
  [ARBITRUM_ONE]: {
    name: 'Arbitrum One',
    scannerUrl: 'https://arbiscan.io',
    currency: 'ETH',
    rpcUrls: ['https://arb1.arbitrum.io/rpc/'],
  },
  [PHALCON]: {
    name: 'Phalcon',
    scannerUrl: '',
    currency: 'tBNB',
    rpcUrls: [
      'https://rpc.phalcon.blocksec.com/rpc_2bc45eeebb054d0ab40d6062d56737a0',
    ],
  },
  [BASE]: {
    name: 'Base',
    scannerUrl: 'https://basescan.org',
    currency: 'ETH',
    rpcUrls: ['https://mainnet.base.org'],
  },
  [ANVIL_1]: {
    name: 'Anvil 1',
    scannerUrl: '',
    currency: 'tBNB',
    rpcUrls: ['http://127.0.0.1:8545'],
  },
  [ANVIL_2]: {
    name: 'Anvil 2',
    scannerUrl: '',
    currency: 'tBNB',
    rpcUrls: ['http://127.0.0.1:8546'],
  },
}
