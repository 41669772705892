import { ContractInterface, type BigNumber } from 'ethers'

import { ERC721Contract } from '..'
import { glpMembershipAbi } from '../../assets'
import { GlpMembershipAttributes, Utils } from '../../types'

export class GlpMembershipContract extends ERC721Contract {
  constructor(
    address: string,
    chain: Utils.Network,
    abi: ContractInterface = glpMembershipAbi,
    rpcUrl?: string
  ) {
    super(address, chain, abi, rpcUrl)
  }

  public lockedAmount(): Promise<BigNumber> {
    return this.adapter!.lockedAmount()
  }

  public getAttributes(tokenId: BigNumber): Promise<GlpMembershipAttributes> {
    return this.adapter!.getAttributes(tokenId)
  }

  async batchSafeTransferFrom(from: string, to: string, ids: number[]) {
    this.wallet!.asBusy()

    try {
      const tx = await this.adapter!.batchSafeTransferFrom(from, to, ids)

      await tx.wait()
    } catch (error) {
      this.wallet!.asIdle()

      throw error
    }

    this.wallet!.asIdle()
  }
}
